/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Label } from '../Label/Label'
import { clickable, option } from '../../assets/styles/styles'
import { flexHorizontallyCentered } from '../../assets/styles/layout'
import { identity } from '@bonitour/common-functions'

const style = css`
  ${option}
  border-radius: 50%;
`

const label = css`
  font-weight: normal;
  user-select: none;
`

const setFirst = css`
  order: -1;
`

const container = css`
  margin: 5px 0;
  margin-right: 15px;
`

export const Radio = ({ customCss = [], ...props }) => (
  <input type='radio' css={[style, ...customCss]} {...props} />
)

export const RadioInputGroup = ({
  id,
  disabled,
  children,
  onChange,
  onChangeReceivesValue = false,
  value,
  ...other
}) => (
  <div css={[flexHorizontallyCentered, container]}>
    <Label css={disabled ? label : [label, clickable]} htmlFor={id} onClick={() => disabled ? identity() : onChange(value)}>
      {children}
    </Label>
    <Radio
      css={setFirst}
      id={id}
      disabled={disabled}
      onChange={onChangeReceivesValue ? event => onChange(event.target.value) : onChange}
      value={value}
      {...other}
    />
  </div>
)
